@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  * {
    font-family: "Open sans";
  }
  input {
    @apply outline-0 border border-gray-200 focus:border-primary rounded-[5px] px-3 py-2;
  }
  button {
    @apply border px-4 py-2 rounded-[4px];
  }
}

@layer components {
  .btn-primary {
    @apply bg-primary text-white;
  }
  .btn-outlined {
    @apply bg-white text-primary border-primary;
  }
}

@font-face {
  font-family: "Montserrat";
  src: url(./assets/fonts/Montserrat-Regular.ttf);
}

@font-face {
  font-family: "Open sans";
  src: url(./assets/fonts/OpenSans-Regular.ttf);
}

@font-face {
  font-family: "Plus Jakarta Sans";
  src: url(./assets/fonts/PlusJakartaSans-VariableFont_wght.ttf);
}

@font-face {
  font-family: "Poppins";
  src: url(./assets/fonts/Poppins-Regular.ttf);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat";
}

p {
  font-family: "Open sans";
}
